.file-dropper {
  position: relative;
  height: 100%;

  .drop-indicator {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2em;
    font-weight: bold;
    z-index: 9;

    &:before {
      pointer-events: none;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: .2;
    }
  }
}