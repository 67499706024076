.ContainerWithMenu {
  & + .menu {
    position: fixed;
    top: 0;
    text-align: center;
    font-size: 18px;
    white-space: nowrap;
    z-index: 9;

    ul {
      list-style-type: none;
      margin: 0;
      display: inline-block;
      background: #000;
      padding: 5px;
      border-radius: 9px;

      li {
        display: inline-block;

        a {
          color: #fff;
          text-decoration: none;
          display: inline-block;
          padding: 0 15px;
        }

        &:not(:last-child) a {
          border-right: 1px solid #fff;
        }
      }
    }
  }
}