button.tts-button {
  border: none;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: #285ad3;
  text-align: center;
  color: #fff;
  font-size: 8px;
  cursor: pointer;

  &:disabled {
    background: #86a5ff;
  }

  &.size-sm {
    width: 20px;
    height: 20px;
    font-size: 6px;
  }

  &.size-lg {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
}