.PagePreview {
  width: 100%;
  background: #fff;
  height: 100%;

  &.loading {
    height: 0;
    padding-bottom: 155%;
    position: relative;

    .spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #000;
    }
  }

  .buttons {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        width: 100%;

        a {
          color: #000;
          border: none;
        }
      }
    }
  }
}