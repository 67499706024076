.NotePreview {
  position: relative;

  --radius: 15px;

  &.drag-clone {
    position: absolute;
    background: #fff;
    transition: transform .5s, opacity .5s;
    border-radius: var(--radius);
    opacity: 1;
    pointer-events: none;

    &.initialized {
      transform: scale(.8);
      opacity: .8;
    }

    &.falling-back {
      transition: left .4s, top .4s, opacity .4s;
      opacity: 0;
    }
  }

  a {
    display: block;
    transition: transform .25s;
    border-radius: var(--radius);
    overflow: hidden;
    box-shadow: 0 0 5px 1px #bdbdbd;

    text-decoration: none;
    color: #000;
  }

  img {
    width: 100%;
  }

  .title {
    text-align: center;
    padding: 10px;
    border-top: 1px solid #efefef;
  }

  a:hover {
    border-color: #8f8f8f;
  }

  &.selection-mode, &.selected {
    & > a {
      transform: scale(0.95);
    }
  }

  .AppIcon {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
  }
}