.RangeSelectorContainer {
  display: flex;
  width: 100%;

  button {
    flex-grow: 0;
    flex-shrink: 0;
    height: 35px;
    width: 20px;
  }
}

.RangeSelector {
  --color: #3b7eff;

  position: relative;
  padding: 10px 15px;
  width: 100%;

  .inner {
    position: relative;
    height: 15px;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(90deg, #f9f9f9, #dddddd);
    cursor: pointer;

    .indicator {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
  }

  .handle {
    position: absolute;
    left: 0;
    top: 5px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 10px;
    line-height: 25px;
    background: #fff;
    color: #000;
    box-shadow: 0 0 4px 0 #8f8f8f;
  }
}