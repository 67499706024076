@import './scss/vars';
@import './scss/mixins';

html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
}

body {
  padding-top: var(--header-height);
  overflow: hidden;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#sf-notes-app {
  background: #9a9a9a;
  touch-action: pan-x pan-y;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
}

.app-button {
  @include AppButton;
}