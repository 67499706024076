#note-activity {
  height: 100%;
  flex-direction: column;

  .canvas-container {
    width: var(--app-max-width);
    margin: 0 auto;
  }

  #btn-add-new-page {
    width: 100%;
    background: #cecece;
    padding: 20px;
    border-radius: 0;
    z-index: 9;
  }
}