@import '../../scss/mixins';

.note-list {
    padding: 20px 20px 70px;
    height: 100%;
    overflow-y: auto;
    transition: opacity .5s;

    &.loading {
        opacity: .5;
        pointer-events: none;
    }

    .notes-count {
        margin: -15px 0 15px;
        font-size: .9em;
    }

    .row-selection-mode {
        display: flex;
        justify-content: space-between;

        h1 {
            margin-top: 0;
        }

        .right {
            text-align: right;

            button, select {
                margin-left: 20px;
            }
        }
    }

    .selection-notice {
        margin: -15px 0 15px;
        font-size: .9em;
    }

    & > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(10, 1fr);

        @media screen and (max-width: 2700px){ grid-template-columns: repeat(9, 1fr) }
        @media screen and (max-width: 2400px){ grid-template-columns: repeat(8, 1fr) }
        @media screen and (max-width: 2100px){ grid-template-columns: repeat(7, 1fr) }
        @media screen and (max-width: 1800px){ grid-template-columns: repeat(6, 1fr) }
        @media screen and (max-width: 1500px){ grid-template-columns: repeat(5, 1fr) }
        @media screen and (max-width: 1200px){ grid-template-columns: repeat(4, 1fr) }
        @media screen and (max-width: 900px){ grid-template-columns: repeat(3, 1fr) }
        @media screen and (max-width: 600px){ grid-template-columns: repeat(2, 1fr) }
        @media screen and (max-width: 350px){ grid-template-columns: 1fr }

        li {
            width: 100%;
            min-height: 260px;
            padding: 10px;
        }
    }
}