@import 'scss/mixins';

.header-menu {
  position: relative;
  display: inline-block;
  height: 100%;

  .AppIcon {
    font-size: 20px;
  }

  a.main-item {
    font-size: 20px;
    margin: 0 5px;
    width: 36px;
    display: inline-block;
    text-align: center;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  & > .menu {
    display: none;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    a.inner {
      display: flex;
      width: 100%;
      color: #000;
      padding: 8px;
      white-space: nowrap;
      text-align: right;
      justify-content: flex-end;

      &:hover {
        background: #efefef;
      }

      .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & > *:last-child {
        width: 36px;
        text-align: center;
      }
    }
  }

  &.open .menu {
    display: block;
  }

  &:not(.is-sub) > .menu {
    @include shadowedBox;
    padding: 5px;
    width: auto;
    position: absolute;
    right: -10px;
    top: 40px;

    .sub {
      display: none;
    }

    li.expanded > .sub {
      display: block;
    }
  }

  &.is-sub {
    background: #efefef;
    width: 100%;
  }
}