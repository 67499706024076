.app-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: hidden;

  .backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .3;
  }

  .modal {
    position: fixed;
    border-radius: 10px;
    left: 50%;
    top: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: 0 0 20px 0 #8f8f8f;
    padding: 14px;
    width: 600px;
    max-width: calc(100% - 40px);
    transition: all .15s;

    header {
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      .btn-close {
        cursor: pointer;
        padding: 10px;
        transform: translate(10px, -10px)
      }
    }

    .content {
      margin: 0 0 15px;

      input {
        width: 100%;
        padding: 4px;
        border: 1px solid #cacaca;

        &:focus {
          outline: none;
          border-color: #9a9a9a;
        }
      }
    }

    .buttons {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;

        li {
          &:not(:last-child) {
            margin-right: 3px;
          }
        }
      }

      button {
        border: none;
        background: none;
        cursor: pointer;

        &:not(:disabled).importance-secondary {
          color: #595959;
        }
      }
    }
  }

  &.animate-jump .modal {
    transform: translate(-50%, -50%) scale(1.05);
  }

  &.app-prompt .modal .content > form > p {
    margin: 0 0 10px;
  }
}