@import '../scss/mixins';

.gesture-container {
  height: var(--viewport-height);
  width: 100%;
  touch-action: none;
}

.gesture-container__inner {
  width: 1200px;
  position: fixed;
}