.ColorSelectionField {
  padding: 5px 0;

  ul.colors {
    margin: 8px 0;
    list-style-type: none;
    padding: 0;
    display: flex;
    width: 100%;
    flex-direction: row;

    li {
      position: relative;
      flex-grow: 1;

      a {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        transition: all .25s;
        padding-bottom: 100%;
      }

      &:not(.selected) .AppIcon {
        opacity: 0;
      }

      &:first-child a {
        border-radius: 5px 0 0 5px;
      }

      &:last-child a {
        border-radius: 0 5px 5px 0;
      }

      &.selected a {
        transform: scale(1.3);
        border-radius: 5px;
        z-index: 2;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: bold;

        .AppIcon {
          //opacity: 1;
          color: #fff;
        }
      }
    }
  }
}