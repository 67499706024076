.page-list {
  position: fixed;
  width: calc(100vw * 0.3);
  max-width: 300px;
  min-width: 200px;
  z-index: 8;
  background: #efefef;
  box-shadow: 0 0 20px 0 #8f8f8f;
  text-align: center;
  top: var(--header-height);
  height: calc(100% - var(--header-height));
  overflow-y: auto;
  padding: 20px 10px;

  ul {
    list-style-type: none;
    margin: 0 20px;
    padding: 0;

    li {
      display: block;
      margin: 10px;

      a {
        display: block;
        border: 2px solid #cfcfcf;

        &:hover {
          border-color: #9a9a9a;
        }
      }

      &.current a {
        border-color: #f00;
      }
    }

    img {
      width: 100%;
      background: #fff;
    }
  }

  .btn-add-page {
    padding: 20px 0;
    width: 100%;
    cursor: pointer;
  }
}

.has-bottom-toolbar .page-list {
  padding: 20px 10px 70px;
}