.LineWidthIcon {
  width: 30px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .inner {
    width: 30px;
    background: #000;
    transition: height .25s;
    border-radius: 4px;
  }
}