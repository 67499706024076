@import '../scss/mixins';

.AppActivity {
  height: 100%;
  padding-top: var(--header-height);

  & > .inner {
    position: relative;
  }

  .fixed-button {
    position: fixed;
    bottom: 15px;
    border-radius: 100%;
    text-align: center;
    height: 50px;
    width: 50px;
    line-height: 50px;
    z-index: 99;
    background: #fff;
    color: #000;
    box-shadow: 0 0 10px 0 #d6d6d6;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}