.bottom-toolbar-button {
  a {
    text-decoration: none;
    text-align: center;
    color: var(--toolbar-button-color);
    border-radius: 10px;
    transition: background .25s;
    margin-top: 2px;
    height: calc(100% - 4px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 45px;

    & > .inner {
      display: block;
    }

    &:hover {
      background: var(--button-hover-bg);
    }

    .icon {
      display: block;
      font-size: 18px;
    }

    label {
      font-size: .7em;
      cursor: pointer;
      display: block;
    }
  }
}