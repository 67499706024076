@import '../scss/mixins';

.sidebar-container {
  display: flex;
  height: 100%;

  --animation-speed: .25s;
}

.sidebar-container__sidebar {
  @include contentHeight;
  width: 60px;
  max-width: calc(100% - 20vw);
  padding: 0 0 0 0 ;
  transition: width var(--animation-speed) ease-out;
  overflow-x: hidden;
  flex-grow: 0;
  flex-shrink: 0;

  .AppIcon {
    font-size: 20px;
  }

  & > .inner {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    padding: 0;
    background: #f1f1f1;
    border-radius: 0 15px 15px 0;

    & > a {
      display: block;
      color: #000;
      padding: 26px 20px;
    }
  }
}

.sidebar-container__content {
  width: 100%;
  min-width: 300px;
  max-height: var(--viewport-height);
}

.AppActivity.has-bottom-toolbar .sidebar-container__content {
  max-height: calc(var(--viewport-height) - 50px);
}

.sidebar-item {
  display: flex;
  color: #000;
  text-decoration: none;
  padding: 10px 20px;
  transition: background .5s;

  &.active, &:hover {
    background: #dfdfdf;
  }

  .icon {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    transition: all var(--animation-speed) ease-out;
    opacity: 0;
  }
}

.sidebar-container.expanded {
  .sidebar-container__sidebar {
    width: 240px;
  }

  .sidebar-item {
    .content {
      transform: translateX(0);
      opacity: 1;
    }
  }
}