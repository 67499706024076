.note-page__edittext {
  position: absolute;
  left: 30px;
  top: 30px;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  outline: none;
  z-index: 3;
  font-size: 45px;
  line-height: 50px;

  &:not(.editable) {
    pointer-events: none;
  }

  p {
    margin: 0;
    padding: 0;
    outline: none;
  }
}