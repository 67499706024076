.BackgroundSelection {
  .list-container {
    overflow-x: auto;
  }

  ul {
    list-style-type: none;
    margin: 0;
    display: flex;
    padding: 10px;

    a {
      display: block;
      text-decoration: none;
      color: #000;
      padding: 10px;
      border: 1px solid transparent;

      canvas {
        border: 1px solid #a9a9a9;
        height: 140px;
        width: 140px;
        margin-bottom: 5px;

        @media screen and (max-width: 600px) {
          width: 80px;
          height: 80px;
        }
      }
    }

    .color-field {
      display: block;
      width: 40px;
      height: 40px;
    }

    li.color a {
      border-color: #efefef;
      padding: 0;
    }

    li.active a {
      border-color: #ccc;
    }

    li.color.active a {
      border-color: #9f9f9f;
    }

    li:not(:last-child) {
      margin-right: 10px;
    }
  }
}