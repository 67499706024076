.note-page__selection-box {
  position: absolute;
  z-index: 2;
  pointer-events: auto !important;

  & > .inner {
    cursor: move;
    width: 100%;
    border: 1px solid #f00;
  }

  .handle {
    position: absolute;
    width: 20px;
    height: 20px;

    &.handle-nw {
      left: -10px;
      top: -10px;
      cursor: nw-resize;
    }
    &.handle-ne {
      right: -10px;
      top: -10px;
      cursor: ne-resize;
    }
    &.handle-se {
      right: -10px;
      bottom: -5px;
      cursor: se-resize;
    }
    &.handle-sw {
      left: -10px;
      bottom: -5px;
      cursor: sw-resize;
    }

    &:after {
      content: '';
      position: absolute;
      background: #f00;
      width: 8px;
      height: 8px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: .5;
      transition: all .5s;
    }

    &:hover:after {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}

.handwriting-to-text {
  .detected-language {
    font-size: 12px;
    display: block;
  }
}

.translation-result {
  .disabled a {
    text-decoration: none;
    color: #000;
  }

  .result-row {
    display: flex;
  }

  .translator {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #acacac;

    .result-row {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    padding-right: 20px;

    @media screen and (max-width: 350px) {
      padding-right: 10px;
    }
  }

  .result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 15px;
  }
}
