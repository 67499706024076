@import 'scss/mixins';

.settings-container {
  @include shadowedBox;
  margin-top: 15px;

  &.is-submenu {
    padding: 5px;
  }

  & > * {
    display: block;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & > span {
      display: block;
    }

    input[type="range"], input[type="color"] {
      width: 100%;
    }

    button {
      color: #000 !important;
    }
  }

  ul {
    display: flex;
    flex-direction: column;

    li.toolbar-button {
      display: flex;
      white-space: nowrap;
      cursor: pointer;
      padding: 8px;

      button {
        color: #000 !important;
      }

      &:hover {
        background: #efefef;
      }

      * {
        margin: 0;
        padding: 0;
      }
    }
  }

  &.right li.toolbar-button {
    flex-direction: row-reverse;
  }
}
