.toolbar-button-list {
  list-style-type: none;
  margin: 0 10px;
  padding: 0;
  display: flex;

  li {
    display: inline-block;
    height: 100%;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}