.folder-tree {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li ul {
    padding-left: 10px;
  }

  .sidebar-item {

    .icon {
      cursor: pointer;
    }

    a {
      color: #000;
      text-decoration: none;
    }

    .content {
      opacity: 1;
    }
  }
}

.bottom-container .folder-tree .sidebar-item {
  padding: 8px;
}