#app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;

  .app-bar {
    background: var(--background);
    display: flex;
    justify-content: space-between;
    padding: var(--padding);
    border-bottom: var(--border-bottom);
    height: 53px;

    button {
      color: #fff;
    }

    & > div > .toolbar-button-list > li > div > button,
    & > div > .toolbar-button-list .toolbar-button-list > li > div > button {
      background: none;
      color: var(--foreground);
      border: none;
      cursor: pointer;
      height: 100%;
      display: flex;
      justify-content: center;
      font-size: var(--button-size);
      padding: var(--button-padding);
    }

    .left {
      display: flex;
    }

    .activity-title {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1.3em;

      &.has-action {
        cursor: pointer;
      }
    }

    .right {
      padding-right: 10px;
    }
  }

  .toolbar {
    background: var(--toolbar-background);
    padding: var(--toolbar-padding);
    height: var(--toolbar-height);
    border-bottom: var(--toolbar-border);
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
  }
}