:root {
  --header-height: 0; /* set programmatically */
  --screen-width: 0; /* set programmatically */
  --screen-height: 0; /* set programmatically */
  --viewport-height: 100%; /* overwritten programmatically if window.visualViewport exists */

  --app-max-width: 1200px;

  --button-fg: #000;
  --button-bg: #efefef;
  --button-hover-bg: #dadada;

  --toolbar-background: #fff;
  --toolbar-padding: 5px;
  --toolbar-height: 50px;
  --toolbar-border: 1px solid #000;

  --toolbar-button-bg: transparent;
  --toolbar-button-active-bg: #efefef;
  --toolbar-button-border: #efefef;
  --toolbar-button-color: #000;
}

#app-header {
  --background: #000;
  --foreground: #fff;
  --padding: 5px;
  --button-size: 20px;
  --button-padding: 10px;
  --border-bottom: none;
}

body.dark {
  :root {
    --button-bg: '#fff'
  }

  --toolbar-background: #2c2c2c;

  --toolbar-button-bg: transparent;
  --toolbar-button-color: #fff;
  --toolbar-button-active-bg: #595959;

  #app-header {
    --border-bottom: 1px solid #454545;
  }
}