@import 'scss/mixins';

.toolbar-button {
  display: inline-block;
  height: 100%;
  position: relative;
  font-size: 16px;

  & > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  button {
    @include AppButton;
    text-align: center;
    background: var(--toolbar-button-bg);
    padding: 0;
    height: 100%;
    border-radius: 20px;
    font-size: 20px;
    margin: 0 5px;
    width: 36px;
    color: var(--toolbar-button-color);

    & > * {
      margin: 0 auto !important;
    }

    &:hover {
      background: transparent;

      &.active {
        background: var(--toolbar-button-active-bg)
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: .5;
    }

    &.has-active-state:not(.active) {
      opacity: .5;
    }
  }
}

.app-bar button {
  &, &:hover, &:active {
    border: none !important;
    background: none !important;
  }
}