.bottom-container {
  position: fixed;
  bottom: -100%;
  background: #fff;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 50px);
  max-width: 800px;
  box-shadow: 0 0 20px 0 #8f8f8f;
  border-radius: 10px 10px 0 0;
  padding: 30px;
  transition: bottom .5s ease-in-out, bottom .5s;

  @media screen and (max-width: 600px) {
    width: calc(100% - 30px);
    padding: 30px 20px;
  }

  @media screen and (max-width: 350px) {
    width: calc(100% - 20px);
  }

  &.shown {
    bottom: 0;
    border-radius: 10px 10px 0 0;
  }

  .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
}