@mixin contentHeight {
  height: calc(var(--viewport-height) - var(--header-height));
}

@mixin AppButton {
  background: none;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
  padding: 5px 15px;
  color: var(--button-fg);

  &:not(.blank) {
  }

  &:not(.blank):hover {
    background: var(--button-hover-bg);
  }

  &.active {
    background: var(--button-bg);
    border-color: var(--button-bg);
  }
}

@mixin shadowedBox {
  position: fixed;
  background: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  box-shadow: 0 0 20px 0 #8f8f8f;
  width: 260px;
  max-width: calc(100vw - 20px);
  z-index: 10;
}