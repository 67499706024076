@import 'scss/mixins';

.bottom-toolbar {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99;
  background: var(--toolbar-background);
  height: var(--toolbar-height);
  display: flex;
  justify-content: center;
  overflow-x: auto;
  box-shadow: 0 0 5px 1px #bdbdbd;
  bottom: calc(var(--screen-height) - var(--viewport-height));

  ul {
    list-style-type: none;
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    overflow-x: auto;
    padding: 0 15px;

    li {
      width: 100%;
      max-width: 160px;
    }
  }
}

.bottom-toolbar-button__settings-container {
  @include shadowedBox;
}