@import '../scss/vars';

.note-page__canvas-wrap {
    position: relative;
    margin: 0;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
}

.note-page__canvas-wrap {
    margin-bottom: 10px;
}

.note-page__canvas {
    background: #fff;
    width: 100%;

    &.loading {
        opacity: .5;
        pointer-events: none;
    }
}

.note-page__eraser-indicator {
    position: absolute;
    border: 1px solid #a1a1a1;
    border-radius: 100%;
    pointer-events: none;
    z-index: 9;

    & > .inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        opacity: .2;
    }
}

.note-page__selection {
    position: absolute;
    border: 1px dashed #000;
    pointer-events: none;
}
